.sb a.active {
  color: #efd99d;
  transition: all 0.5s ease-in-out;
  --tw-border-opacity: 1;
  border-right-color: rgb(239 217 157 / var(--tw-border-opacity));
  border-right-width: 4px;
}

.sb a.active .st {
  padding-left: 10px;
}

.sb-i-c {
  background-color: #5d7d73;
  box-shadow: 2px 3px 6px #26473d2e;
  border-radius: 10px;
  right: -1.5em;
}
