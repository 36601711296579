.header-notif {
  background-color: #bbab7f;
  border-radius: 5px;
}

.header-multilang select {
  outline: none;
  border: 1px solid #fff;
  border-radius: 0.5rem;
  box-shadow: 0 1px 4px rgb(146 161 176 / 15%);
  cursor: pointer;
}

.header-multilang select:focus,
select:hover {
  outline: none;
  border: 1px solid rgba(0, 0, 0, 0.329);
}

.header-profil {
  background-color: #5d7d73;
  box-shadow: 6px 8px 15px #2b615240;
  border-radius: 10px;
}
